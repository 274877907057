@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Space Grotesk';
  src: url('/fonts/SpaceGrotesk-Regular.otf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
@font-face {
  font-family: 'Space Grotesk';
  src: url('/fonts/SpaceGrotesk-Medium.otf');
  font-style: medium;
  font-weight: 500;
  font-display: swap;
}
@font-face {
  font-family: 'Space Grotesk';
  src: url('/fonts/SpaceGrotesk-SemiBold.otf');
  font-style: semibold;
  font-weight: 600;
  font-display: swap;
}
@font-face {
  font-family: 'Space Grotesk';
  src: url('/fonts/SpaceGrotesk-Bold.otf');
  font-style: bold;
  font-weight: 700;
  font-display: swap;
}

:root {
  --sat: env(safe-area-inset-top);
  --sar: env(safe-area-inset-right);
  --sab: env(safe-area-inset-bottom);
  --sal: env(safe-area-inset-left);
  --fill-height: env(-webkit-fill-available);
}

@layer utilities {
  .safearea-pad-y {
    padding-top: env(safe-area-inset-top, 0);
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
  .safearea-pad-top {
    padding-top: env(safe-area-inset-top, 0);
  }
  .safearea-pad-bot {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }

  .safearea-spacer-top {
    height: env(safe-area-inset-top, 0);
  }
  .safearea-spacer-bot {
    height: env(safe-area-inset-bottom, 0);
  }

  .toast-force-safe-area-top {
    top: max(env(safe-area-inset-top, 0), 16px) !important;
  }

  .h-safe-screen-desktop {
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
  }

  /* For content that you want as the exact height of the screen
  Typically for child content that scrolls */
  .h-safe-screen {
    height: stretch;
    height: fill-available;
    height: -moz-available;
    height: -webkit-fill-available;

    min-height: fill-available;
    min-height: -moz-available;
    min-height: -webkit-fill-available;

    max-height: 100vh;

    @apply sm:h-safe-screen-desktop;
  }
}

.parity-banner {
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  z-index: 50;
}

.parity-banner .parity-banner-close-btn {
  top: 1.5rem !important;
}

.parity-banner.parity-banner-has-logo {
  padding-left: 10px !important;
}

.parity-banner-logo {
  display: none;
}

@media (max-width: 600px) {
  .parity-banner .parity-banner-close-btn {
    top: 5px !important;
    right: 0.5rem;
  }
}

.prose.inner-details {
  strong {
    @apply text-white;
  }

  a {
    @apply text-white;
  }

  a:visited {
    @apply text-white;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Space Grotesk', sans-serif;
    @apply text-white;
  }

  figure {
    @apply flex justify-center;
  }

  blockquote {
    @apply text-white;
  }

  ol,
  ul,
  ol li::marker,
  ul li::marker {
    @apply text-white;
  }
}
